//
// tabs.scss
//

.nav-tabs,
.nav-pills {
    >li {
        >a {
            color: #{map-get($grays, "700")};
            font-weight: $font-weight-semibold;
        }
    }
}

.nav-scroll-container {
    display: flex;
    background-color: white;
    overflow-x: auto; // Enable horizontal scrolling
    white-space: nowrap; // Prevent wrapping of items
    align-items: center;
}

.nav-scroll-container .nav {
    display: inline-block !important; // Ensure nav items stay in a row
}

.nav-scroll-container .nav-item {
    display: inline-block !important; // Ensure nav items stay in a row
}

.nav-pills {
    >a {
        color: #{map-get($grays, "700")};
        font-weight: $font-weight-semibold;
    }
}

.bg-nav-pills {
    background-color: $nav-pills-bg;
}

//
// nav-bordered
//

.nav-tabs.nav-bordered {
    border-bottom: 2px solid #{rgba(map-get($grays, "600"), 0.2)};

    .nav-item {
        margin-bottom: -1px;
    }

    li {
        a {
            border: 0;
            padding: 0.625rem 1.25rem;
        }

        a.active {
            border-bottom: 2px solid $primary;
        }
    }
}

// Dark mode
body[data-layout-color="dark"] {

    .nav-tabs,
    .nav-pills {
        >li {
            >a {
                color: #{map-get($dark-grays, "700")};
            }
        }
    }

    .nav-pills {
        >a {
            color: #{map-get($dark-grays, "700")};
        }
    }

    .nav-tabs.nav-bordered {
        border-bottom: 2px solid #{rgba(map-get($dark-grays, "600"), 0.2)};
    }
}

.client-nav, .onboarding-nav {
    .nav-item {
        background-color: #eef2f7;

        a {
            color: #7d7d7d;
        }

        a.active {
            background-color: #364fb9;
            color: white;
        }
    }
}